import './src/static/css/global.scss'
import { changeLocale } from "gatsby-plugin-intl-v6";

const validLanguages = ['en', 'zh', 'vn', 'id']

const fetchUserCountry = async () => {
  try {
    const response = await fetch("https://ipapi.co/json/");
    const data = await response.json();
    console.log('fetch ip country code: ', data)
    return data.country_code;
  } catch (error) {
    console.error("Error fetching user location:", error);
    return null;
  }
};

const getLanguageFromURL = () => {
  const pathSegments = window.location.pathname.split('/').filter(Boolean)
  return validLanguages.includes(pathSegments[0]) ? pathSegments[0] : null
}

const getNavigateLang = () => {
  console.log('navigator.language:', navigator.language)
  const browserLang = navigator.language ? navigator.language.substr(0, 2) : ''
  return validLanguages.includes(browserLang) ? browserLang : null
} 

const countryToLanguageMap = {
  US: "en", // 美国 -> 英文
  GB: "en", // 英国 -> 英文
  CN: "zh", // 中国 -> 中文
  VN: "vn", // 越南
  ID: "id", // 印尼
};

const getLanguageFromCountry = (countryCode) => {
  console.log('countryToLanguage:', countryToLanguageMap[countryCode])
  return countryToLanguageMap[countryCode] || "en";
};

export const onInitialClientRender = async () => {
  // 用户传入的语言标签优先级最高
  const urlLanguage = getLanguageFromURL()
  console.log("urlLanguage: ", urlLanguage)
  if (urlLanguage) {
    changeLocale(urlLanguage)
    return
  }

  // 检测 gatsby-plugin-intl 默认的 localStorage 值
  // localStorage.removeItem("gatsby-intl-language");
  const usedLanguage = localStorage.getItem("gatsby-intl-language");
  console.log("usedLanguage: ", usedLanguage)
  if (!usedLanguage) { // 如果是第一次访问则根据IP地区选择语言
    // 检测浏览器语言
    const browserLang = getNavigateLang()
    if (browserLang) {
      changeLocale(browserLang)
      return
    }
    try {
      const countryCode = await fetchUserCountry()
      const language = getLanguageFromCountry(countryCode)
      console.log("fetchUserCountry language: ", language)
      changeLocale(language)
    } catch (error) {
      console.error("Error determining language:", error)
      changeLocale("en")
    }
  } else {
    changeLocale(usedLanguage)
  }

  localStorage.removeItem("usedLanguage"); // 删除历史遗留
  // const usedLanguage = localStorage.getItem("usedLanguage");
  // console.log('localStorage usedLanguage: ' + usedLanguage)
  // if (usedLanguage) {
  //   changeLocale(usedLanguage);
  // }
  
};

export const onClientEntry = () => {
  if (navigator.userAgent.match(/MicroMessenger/i)) {
    // document.documentElement.style.fontSize = '16px';
    // document.body.style.fontSize = '16px';
    document.documentElement.style.setProperty('font-size', '16px', 'important');
    document.body.style.setProperty('font-size', '16px', 'important');
    document.body.style.setProperty('-webkit-text-size-adjust', 'none', 'important');
    document.body.style.setProperty('text-size-adjust', 'none', 'important');
  }
};