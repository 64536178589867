module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"AB","short_name":"AB","start_url":"/","background_color":"#f7fbff","theme_color":"#f7fbff","display":"standalone","path":"/builds/t3_din8DD/0/wangyifan/ab-website/newton-website/src/static/images","icon":"src/static/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"3948eaac6df661f53db0360ddc814210"},
    },{
      plugin: require('../node_modules/gatsby-plugin-intl-v6/gatsby-browser.js'),
      options: {"plugins":[],"path":"/builds/t3_din8DD/0/wangyifan/ab-website/newton-website/src/static/intl","languages":["en","zh","vn","id"],"defaultLanguage":"en","redirect":false},
    },{
      plugin: require('../node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[],"offsetY":0,"className":"anchor"},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":650,"linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"backgroundColor":"white","quality":50,"withWebp":false,"withAvif":false,"tracedSVG":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".mdx",".md"],"gatsbyRemarkPlugins":[{"resolve":"/builds/t3_din8DD/0/wangyifan/ab-website/newton-website/node_modules/gatsby-remark-images","id":"c1068fdb-9ffa-5304-9e63-3c74cd6826e6","name":"gatsby-remark-images","version":"6.20.0","modulePath":"/builds/t3_din8DD/0/wangyifan/ab-website/newton-website/node_modules/gatsby-remark-images/index.js","pluginOptions":{"plugins":[],"backgroundColor":"transparent","maxWidth":1200},"nodeAPIs":["pluginOptionsSchema"],"browserAPIs":["onRouteUpdate"],"ssrAPIs":[]}],"defaultLayouts":{},"lessBabel":false,"remarkPlugins":[],"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/builds/t3_din8DD/0/wangyifan/ab-website/newton-website","commonmark":false,"JSFrontmatterEngine":false,"engines":{}},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"backgroundColor":"transparent","maxWidth":1200},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-116218760-1","head":true,"anonymize":false,"respectDNT":false,"exclude":[],"pageTransitionDelay":0,"enableWebVitalsTracking":false},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
